import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

const Videos = () => {
  return (
    <Layout activeMenu="videos">
      <Seo title="Videos"></Seo>
      <section className="container">
        <div className="section-title">
          <h2>Videos</h2>
        </div>
      </section>
    </Layout>
  );
};

export default Videos;
